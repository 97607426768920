export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const index_contract_address = "0x9ba8648fF0B7ebbF584b879c9aD81977F27D55df";
export const usdt_contract_address = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
export const projectX_contract_address = "0xe4F1BDF9E4f37F7DB5045129D983f005AaEd7AEA";