import React from "react";
import { IoPlayOutline } from "react-icons/io5";
const TextIntroSection = () => {
  return (
    <div className="relative py-10 hidden lg:block">
      {/* <section className="ani__wrap text-intro-section ">
        <div className="max-w-screen-xl mx-auto">
          <div className="sec-title">
            <div className="bg-text !hidden lg:!flex ">
              <span className="block top-text x--20">
                <span
                  className="bg__text fade__right"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 0px)",
                    opacity: 1,
                  }}
                >
                  EarthMeta{" "}
                </span>
              </span>
              <span className="block bottom-text x--5">
                <span
                  className="bg__text fade__left"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 0px)",
                    opacity: 1,
                  }}
                >
                  EarthMeta{" "}
                </span>
              </span>
            </div>
            <div
              className="grid grid-cols-12 gx-lg-5 items-center"
              id="aboutus"
            >
              <div
                className="col-span-12 lg:col-span-8 mb-5 lg:mb-0 fade__left flex items-center justify-between px-5 py-5"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <h2 className="title uppercase title-36 font-bold flex items-center justify-between text-[28px] md:text-[40px] pr-[20px]" style={{fontFamily:'Benzin',lineHeight:1}}>
                  Earthmeta is Metaverse at a whole new level!
                  <div className="bg-text flex lg:!hidden">
                    <span className="block top-text x--20">
                      <span
                        className="bg__text fade__right py-5"
                        style={{
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform: "translate(0px, 0px)",
                          opacity: 1,
                        }}
                      >
                        EarthMeta{" "}
                      </span>
                    </span>
                    <span className="block bottom-text x--5 py-5">
                      <span
                        className="bg__text fade__left"
                        style={{
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform: "translate(0px, 0px)",
                          opacity: 1,
                        }}
                      >
                        EarthMeta{" "}
                      </span>
                    </span>
                  </div>
                </h2>
                <button
                  data-src="https://www.youtube.com/watch?v=J-iGqMEwFjY"
                  className="play-btn md:mx-8 flex-1"
                >
                  <span className="wave-1 waves"></span>
                  <span className="wave-2 waves"></span>
                  <span className="wave-3 waves"></span>
                  <IoPlayOutline />
                </button>
              </div>
              <div
                className="col-span-12 lg:col-span-4 text-20 fade__right px-5"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <p>
                  EarthMeta is the first decentralized AI-powered metaverse
                  allowing users to own the world. Govern, interact, and create
                  an economy in the Metaverse through smart contracts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
       <video
            autoPlay
            loop
            muted
            className="object-cover w-full h-full z-0"
          >
            <source src="assets/videos/projectX.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
    </div>
  );
};
export default TextIntroSection;
