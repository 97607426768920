import React, { useEffect, useState } from "react";
import moment from "moment";

const Clock = ({ currentRound }) => {
  const tgeTimestamp = 1726790774;
  const firstCliffDuration = 5184000;
  const secondCliffDuration = 2592000;
  const firstCliffDate = moment
    .unix(tgeTimestamp)
    .add(firstCliffDuration, "seconds");
  const secondCliffDate = moment
    .unix(tgeTimestamp)
    .add(firstCliffDuration + secondCliffDuration, "seconds");

  const [firstCliffCountdown, setFirstCliffCountdown] = useState({});
  const [secondCliffCountdown, setSecondCliffCountdown] = useState({});
  useEffect(() => {
    const updateCountdowns = () => {
      const now = moment();

      const updateCountdown = (cliffDate) => {
        const duration = moment.duration(cliffDate.diff(now));
        return {
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        };
      };

      setFirstCliffCountdown(updateCountdown(firstCliffDate));
      setSecondCliffCountdown(updateCountdown(secondCliffDate));
    };

    updateCountdowns();
    const interval = setInterval(updateCountdowns, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-wrap justify-center items-center gap-x-8 gap-y-3">
      <div className="clock-item text-center relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          data-inject-url="https://presale.earthmeta.ai/assets/img/clock-day.svg"
          className="get-svg-code"
        >
          <g id="Day">
            <path
              id="inner__circle"
              d="M39 68.75C55.4305 68.75 68.75 55.4305 68.75 39C68.75 22.5695 55.4305 9.25 39 9.25C22.5695 9.25 9.25 22.5695 9.25 39C9.25 55.4305 22.5695 68.75 39 68.75Z"
              stroke="url(#paint0_linear_1044_106)"
              strokeWidth="0.5"
              data-svg-origin="39 39"
              transform="matrix(0.16251,0.98671,-0.98671,0.16251,71.1438,-5.81958)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            ></path>
            <g
              id="Outline__circle"
              data-svg-origin="39 39"
              transform="matrix(0.16251,-0.98671,0.98671,0.16251,-5.81958,71.1438)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            >
              <path
                id="child_border_oc"
                d="M39 77C59.9868 77 77 59.9868 77 39C77 18.0132 59.9868 1 39 1C18.0132 1 1 18.0132 1 39C1 59.9868 18.0132 77 39 77Z"
                stroke="black"
                strokeWidth="0.5"
                // style="opacity: 0;"
              ></path>
              <path
                id="Ellipse 32"
                d="M57.1988 5.92383C62.4758 8.82685 66.9816 12.9503 70.3399 17.9499C73.6982 22.9496 75.8115 28.6802 76.5033 34.6631C77.1951 40.6461 76.4454 46.7078 74.3166 52.3419C72.1879 57.976 68.7419 63.0189 64.2666 67.0495C59.7914 71.0802 54.4166 73.9815 48.5913 75.5113C42.766 77.041 36.6592 77.1547 30.7809 75.8429C24.9027 74.5311 19.4237 71.8318 14.8014 67.9705C10.1792 64.1092 6.54794 59.1981 4.21094 53.6471"
                stroke="url(#paint1_linear_1044_106)"
                strokeWidth="0.5"
              ></path>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1044_106"
              x1="9"
              y1="39"
              x2="69"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_1044_106"
              x1="1.00319"
              y1="38.9992"
              x2="77.0032"
              y2="38.9992"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
          </defs>
        </svg>
        <span
          className="absolute top-2/4 left-2/4 leading-none font-bold"
          style={{ transform: "translate(-50%,-50%)" }}
        >
          {currentRound === 0
            ? firstCliffCountdown.days
            : secondCliffCountdown.days}{" "}
          <small className="block font-normal">Day</small>
        </span>
      </div>
      <div className="clock-item text-center relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          data-inject-url="https://presale.earthmeta.ai/assets/img/clock-hours.svg"
          className="get-svg-code"
        >
          <g id="Hours">
            <path
              id="inner__circle"
              d="M39 68.75C55.43 68.75 68.75 55.4305 68.75 39C68.75 22.5695 55.43 9.25 39 9.25C22.57 9.25 9.25 22.5695 9.25 39C9.25 55.4305 22.57 68.75 39 68.75Z"
              stroke="url(#paint0_linear_1044_114)"
              strokeWidth="0.5"
              data-svg-origin="39 39"
              transform="matrix(-0.99877,-0.04963,0.04963,-0.99877,76.01646,79.8876)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            ></path>
            <g
              id="Outline__circle"
              data-svg-origin="39 39"
              transform="matrix(-0.99877,0.04963,-0.04963,-0.99877,79.8876,76.01646)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            >
              <path
                id="child_border_oc_2"
                d="M39 77C59.987 77 77 59.9868 77 39C77 18.0132 59.987 1 39 1C18.013 1 1 18.0132 1 39C1 59.9868 18.013 77 39 77Z"
                stroke="black"
                strokeWidth="0.5"
                // style="opacity: 0;"
              ></path>
              <path
                id="parent-circle-0"
                d="M68.8551 62.1026C63.8371 68.5879 56.8431 73.2627 48.9321 75.4201C41.0201 77.5775 32.6221 77.1002 25.0061 74.0602C17.3901 71.0203 10.9711 65.5832 6.71911 58.571C2.46811 51.5589 0.616114 43.3531 1.44211 35.1946C2.26911 27.0362 5.73011 19.3687 11.3011 13.3518C16.8721 7.33496 24.2521 3.29584 32.3221 1.84532C40.3931 0.394802 48.7171 1.61177 56.0351 5.3122C63.3531 9.01262 69.2671 14.9952 72.8821 22.3553"
                stroke="url(#paint1_linear_1044_114)"
                strokeWidth="0.5"
              ></path>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1044_114"
              x1="9"
              y1="39"
              x2="69"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_1044_114"
              x1="1.00011"
              y1="39"
              x2="77.0001"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
          </defs>
        </svg>
        <span
          className="absolute top-2/4 left-2/4 leading-none font-bold"
          style={{ transform: "translate(-50%,-50%)" }}
        >
          {currentRound === 0
            ? firstCliffCountdown.hours
            : secondCliffCountdown.hours}
          <small className="block font-normal">Hour</small>
        </span>
      </div>
      <div className="clock-item text-center relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          data-inject-url="https://presale.earthmeta.ai/assets/img/clock-min.svg"
          className="get-svg-code"
        >
          <g id="Minute">
            <path
              id="inner__circle"
              d="M39 68.75C55.43 68.75 68.75 55.4305 68.75 39C68.75 22.5695 55.43 9.25 39 9.25C22.57 9.25 9.25 22.5695 9.25 39C9.25 55.4305 22.57 68.75 39 68.75Z"
              stroke="url(#paint0_linear_1044_122)"
              strokeWidth="0.5"
              data-svg-origin="39 39"
              transform="matrix(0.99444,0.10527,-0.10527,0.99444,4.32237,-3.88869)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            ></path>
            <g
              id="Outline__circle"
              data-svg-origin="39 39"
              transform="matrix(0.99444,-0.10527,0.10527,0.99444,-3.88869,4.32237)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            >
              <path
                id="child_border_oc_3"
                d="M39 77C59.987 77 77 59.9868 77 39C77 18.0132 59.987 1 39 1C18.013 1 1 18.0132 1 39C1 59.9868 18.013 77 39 77Z"
                stroke="black"
                strokeWidth="0.5"
                // style="opacity: 0;"
              ></path>
              <path
                id="Ellipse 32_3"
                d="M1.25 39.0001C1.25 29.9375 4.51 21.1774 10.435 14.3199C16.36 7.46236 24.554 2.96518 33.521 1.64983C42.488 0.33447 51.628 2.28873 59.273 7.15562C66.918 12.0225 72.557 19.4772 75.16 28.158C77.762 36.8387 77.155 46.1661 73.45 54.4364C69.744 62.7067 63.187 69.3678 54.976 73.2031C46.765 77.0383 37.448 77.7916 28.727 75.3255C20.007 72.8593 12.464 67.3382 7.478 59.7708"
                stroke="url(#paint1_linear_1044_122)"
                strokeWidth="0.5"
              ></path>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1044_122"
              x1="9"
              y1="39"
              x2="69"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_1044_122"
              x1="1"
              y1="39.0001"
              x2="77"
              y2="39.0001"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
          </defs>
        </svg>
        <span
          className="absolute top-2/4 left-2/4 leading-none font-bold"
          style={{ transform: "translate(-50%,-50%)" }}
        >
          {currentRound === 0
            ? firstCliffCountdown.minutes
            : secondCliffCountdown.minutes}{" "}
          <small className="block font-normal">Min</small>
        </span>
      </div>
      <div className="clock-item text-center relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          data-inject-url="https://presale.earthmeta.ai/assets/img/clock-sec.svg"
          className="get-svg-code"
        >
          <g id="sec">
            <path
              id="inner__circle"
              d="M39.0078 68.75C55.4378 68.75 68.7578 55.4305 68.7578 39C68.7578 22.5695 55.4378 9.25 39.0078 9.25C22.5768 9.25 9.25781 22.5695 9.25781 39C9.25781 55.4305 22.5768 68.75 39.0078 68.75Z"
              stroke="url(#paint0_linear_1044_130)"
              strokeWidth="0.5"
              data-svg-origin="39.00780391693115 39"
              transform="matrix(-0.81068,0.58549,-0.58549,-0.81068,93.46476,47.77784)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            ></path>
            <g
              id="Outline__circle"
              data-svg-origin="39 39"
              transform="matrix(-0.81068,-0.58549,0.58549,-0.81068,47.78241,93.45063)"
              // style="translate: none; rotate: none; scale: none; transform-origin: 0px 0px;"
            >
              <path
                id="child_border_oc_4"
                d="M39 77C59.987 77 77 59.9868 77 39C77 18.0132 59.987 1 39 1C18.013 1 1 18.0132 1 39C1 59.9868 18.013 77 39 77Z"
                stroke="black"
                // style="opacity: 0;"
              ></path>
              <path
                id="Ellipse 32_4"
                d="M57.4936 71.9131C51.9486 75.0281 45.7066 76.6916 39.3466 76.7492C32.9866 76.8067 26.7156 75.2566 21.1146 72.2426C15.5146 69.2285 10.7666 64.8484 7.31058 59.5087C3.85558 54.169 1.80556 48.043 1.35156 41.6992"
                stroke="url(#paint1_linear_1044_130)"
                strokeWidth="0.5"
              ></path>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1044_130"
              x1="9.00781"
              y1="39"
              x2="69.0078"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_1044_130"
              x1="1.00456"
              y1="39.0007"
              x2="77.0046"
              y2="39.0007"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF85"></stop>
              <stop offset="1" stopColor="#430099"></stop>
            </linearGradient>
          </defs>
        </svg>
        <span
          className="absolute top-2/4 left-2/4 leading-none font-bold"
          style={{ transform: "translate(-50%,-50%)" }}
        >
          {currentRound === 0
            ? firstCliffCountdown.seconds
            : secondCliffCountdown.seconds}{" "}
          <small className="block font-normal">Sec</small>
        </span>
      </div>
    </div>
  );
};

export default Clock;
