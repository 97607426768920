import React, { useEffect } from "react";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
const Navbar = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();

  return (
    <nav>
      <div className="max-w-screen-xl mx-auto ">
        <div className="w-full">
          <div className="flex w-full justify-between px-2 items-center">
            <div className="hidden md:flex px-5 w-36"></div>
            <div className="flex flex-1 relative md:justify-center md:items-center">
              <a href="/" className="flex items-center w-24">
                <img
                  src="assets/img/Logo-white-transparant.png"
                  alt="Logo"
                  className="max-h-full max-w-full"
                />
              </a>
            </div>
            <div className="flex items-center justify-end ">
              <button
                onClick={() => open()}
                className="font-semibold text-white text-md rounded-2xl border-gradient border-2 px-5 py-1 w-36 truncate"
              >
                {!isConnected ? "Buy OXO" : address}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
