import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
// 1. Get projectId
const projectId = "ca83ad303f3fc437fbc06751b0a78935";

// 2. Set chains
const mainnet = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: "https://polygon-rpc.com",
};

// 3. Create a metadata object
const metadata = {
  name: "localhost",
  description: "My Website description",
  url: "http://localhost:3000", // origin must match your domain & subdomain
  icons: [
    "http://localhost:3000/assets/img/Logo-projectX-black-transparant.png",
  ],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableCoinbase:false
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
