import React from "react";
import Connector from "./utils/connector";
import Body from "./components/body";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
      <Body />
      <Footer />
    </div>
  );
}

export default App;
