import React from "react";
import Navbar from "./navbar";
import Buy from "./buy";
import CustomRangeSlider from "./customRangeSlider";
import TextIntroSection from "./textIntroSection";
import Calculator from "./calculator";

const Body = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <div className="relative">
          <video
            autoPlay
            loop
            muted
            className="object-cover w-full h-full absolute z-0"
          >
            <source src="assets/videos/title.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="max-w-screen-xl mx-auto md:pb-10 md:mb-20 px-5 relative z-10">
            <Navbar />
            <div className="mb-10 lg:mb-20">
              <CustomRangeSlider />
            </div>
            <Buy />
          </div>
        </div>
        <TextIntroSection />
        <Calculator />
        <TextIntroSection />
      </div>
    </>
  );
};

export default Body;
