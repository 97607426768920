import React, { useState, useEffect } from "react";
import { WALLETCONTEXT } from "../contexts/walletContext";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { utils } from "ethers";
const CustomRangeSlider = () => {
  const { currentRound, getIndexContract } = WALLETCONTEXT();
  const { isConnected } = useWeb3ModalAccount();
  const [raised, setRaised] = useState(0);
  const [maxRaised, setMaximumRaised] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  useEffect(() => {
    if (isConnected) {
      getData();
      getNextStageData();
    }
  }, [isConnected]);
  const getData = async () => {
    try {
      const contract = await getIndexContract();
      let result = await contract.tokenSold();
      const tokenSold = utils.formatEther(result);
      const round = await contract.rounds(currentRound);
      const price = utils.formatEther(round.price);
      result = tokenSold / price;
      setRaised(Math.round(result));
      setCurrentPrice(parseFloat(1 / price).toFixed(5));
    } catch (error) {
      console.log(error.message);
    }
  };
  const getNextStageData = async () => {
    try {
      const contract = await getIndexContract();
      const round = await contract.rounds(currentRound + 1);
      const tokenAmount = utils.formatEther(round.amount);
      const price = utils.formatEther(round.price);
      setMaximumRaised(Math.round(tokenAmount / price));
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="range-slider-container container-lg">
      <div className="flex justify-between w-full mb-2">
        <div className="flex-1 uppercase">
          <h1 className="text-[#02e4fb]">RAISED - ${raised}</h1>
        </div>
        <div className="flex-1">
          <h1 className="text-right uppercase">
            <span>Stage {currentRound + 2} Target - </span>
            <span className="text-[#02e4fb]">${maxRaised}</span>
          </h1>
        </div>
      </div>
      <div className="range-slider">
        <div className="range-slider-track mb-2">
          <input
            type="range"
            min="0"
            max={maxRaised}
            value={raised}
            onChange={() => console.log("")}
            className="slider"
            style={{ width: "100%" }}
            disabled
          />
        </div>
        <div className="flex justify-between items-center">
          <div>
            <h1>Current Price</h1>
            <h1 className="text-[#02e4fb]">1 $OXO = ${currentPrice}</h1>
          </div>
          <div className="text-right ">
            <h1>Listing Price</h1>
            <h1 className="text-[#02e4fb]">1 $OXO = $ 0.00012</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomRangeSlider;
