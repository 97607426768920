import React from "react";

const Footer = () => {
  return (
    <div className="border-t-2 py-2">
      <div>
        <p className="text-center">&copy; Project X Ltd. All rights reserved.</p>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <p className="text-center">
          Disclaimer: Cryptocurrency may be unregulated in your jurisdiction.
          The value of cryptocurrencies may go down as well as up. Profits may
          be subjected to capital gains or other taxes applicable in your
          jurisdiction.
        </p>
      </div>
    </div>
  );
};

export default Footer;
