import React, { useEffect, useState } from "react";
import { WALLETCONTEXT } from "../contexts/walletContext";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { utils } from "ethers";
const Calculator = () => {
  const { currentRound, getIndexContract } = WALLETCONTEXT();
  const { isConnected } = useWeb3ModalAccount();
  const [livePrice, setLivePrice] = useState(3 / 100000);
  const [amount, setAmount] = useState("");
  const [currentPrice, setCurrentPrice] = useState(0);
  useEffect(() => {
    if (isConnected) {
      getData();
    }
  }, [isConnected]);
  const getData = async () => {
    try {
      const contract = await getIndexContract();
      let result = await contract.tokenSold();
      const tokenSold = utils.formatEther(result);
      const round = await contract.rounds(currentRound);
      const price = utils.formatEther(round.price);
      result = tokenSold / price;
      setCurrentPrice(parseFloat(1 / price).toFixed(5));
    } catch (error) {
      console.log(error.message);
    }
  };
  const scrollToSection = (e) => {
    e.preventDefault()
    const element = document.getElementById('buy');
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="my-20">
      <div className="max-w-screen-xl mx-auto py-20 px-5">
        <div className="md:grid md:grid-cols-12 md:gap-x-10 gap-y-10 flex flex-col-reverse">
          <div className="col-span-12 md:col-span-5 border-gradient-buy border-4 px-5 py-5">
            <div className="border-gradient-buy-bg"></div>
            <h1 className="uppercase text-center text-xl text-[#02e4fb] mb-1">
              Returns calculator
            </h1>
            <p className="md:w-4/6 mx-auto text-center mb-5 break-words">
              Input the amount of $0X0 you're purchasing, and see what it would
              be worth at different prices.
            </p>
            <div className="flex flex-wrap justify-between items-center gap-y-5 mb-2">
              <div>
                <h1 className="uppercase text-[#02e4fb]">If i buy</h1>
              </div>
              <div>
                <h1 className="uppercase text-[#02e4fb]">
                  ${parseFloat(currentPrice * amount).toFixed(5)}
                </h1>
              </div>
            </div>

            <div className="relative flex items-center border rounded-md  mb-5 px-3 bg-white overflow-hidden">
              <input
                type="number"
                id="animated-input"
                className="peer h-12 w-full px-3 border-none focus:outline-none placeholder-transparent text-sm bg-white text-gray-400"
                placeholder=" "
                value={amount}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const normalizedValue = inputValue
                    ? String(Number(inputValue))
                    : "";
                  setAmount(normalizedValue);
                }}
              />
              <label
                htmlFor="animated-input"
                className="absolute left-6 top-0.5 text-gray-600 text-xs transition-all duration-300 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-0.5 peer-focus:text-xs peer-focus:text-blue-600"
              >
                You Buy:
              </label>
              <div className="w-12 h-12 bg-white">
                <img
                  className="w-full h-full object-cover"
                  src="assets/img/Logo-projectX-black-transparant.png"
                  alt="projectx"
                />
              </div>
            </div>
            <div>
              <div className="mb-3">
                <h1>And the token price reaches: ${livePrice}</h1>
              </div>
              <div className="range-slider">
                <div className="range-slider-track mb-2">
                  <input
                    type="range"
                    defaultValue={3}
                    min="3"
                    max="1000"
                    onChange={(e) => setLivePrice(e.target.value / 100000)}
                    className="slider"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <h1>Your $OXO would be worth</h1>
                  <h1 className="text-2xl">
                    ${parseFloat(livePrice * amount).toFixed(2)}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-7 flex flex-col items-center justify-around mb-20 md:mb-0 gap-y-10">
            <h1
              className="text-6xl md:text-8xl font-bold text-center"
              style={{ fontFamily: "BebasNeueCyrillic" }}
            >
              <span
                className="text-[#02e4fb]"
                style={{ fontFamily: "BebasNeueCyrillic" }}
              >
                Ready
              </span>{" "}
              to Buy?
            </h1>
            <p className="font-bold text-2xl md:w-5/6 mx-auto text-center break-words normal-case">
              All you need to buy{" "}
              <span className="text-[#02e4fb] font-black">$OXO</span> tokens at
              the lowest presale price is a decentralised wallet containing
              Matic/usdt. Simply connect to the widget above to buy your tokens!
            </p>
            <a href="#" onClick={(e)=>scrollToSection(e)} className="gradient-button border px-12 py-2 ">buy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
