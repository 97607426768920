import React, { createContext, useContext, useEffect, useState } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { providers, Contract } from "ethers";
import {
  index_contract_address,
  usdt_contract_address,
  projectX_contract_address,
} from "../utils/index";
import indexContractAbi from "../utils/abis/index.json";
import usdtContractAbi from "../utils/abis/usdt.json";
import porjectXContractAbi from "../utils/abis/projectX.json";

const WalletContext = createContext({});
export const WalletProvider = ({ children }) => {
  const [currentRound, setCurrentRound] = useState(0);
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  useEffect(() => {
    if (isConnected) {
      getCurrentRound();
    }
  }, [isConnected]);
  const getProvider = async () => {
    const ethersProvider = new providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    return signer;
  };
  const getIndexContract = async () => {
    const signer = await getProvider();
    const indexContract = new Contract(
      index_contract_address,
      indexContractAbi,
      signer
    );
    return indexContract;
  };
  const getUsdtContract = async ( )=>{
    const signer = await getProvider();
    const usdtContract = new Contract(
      usdt_contract_address,
      usdtContractAbi,
      signer
    );
    return usdtContract;
  }
  const getProjectXContract = async()=>{
    const signer = await getProvider();
    const projectXContract = new Contract(
      projectX_contract_address,
      porjectXContractAbi,
      signer
    );
    return projectXContract;
  }
  const getCurrentRound = async () => {
    try {
      const contract = await getIndexContract();
      const currentRound = await contract.currentRound();
      setCurrentRound(currentRound);
    } catch (error) {
      throw error;
    }
  };
  return (
    <WalletContext.Provider value={{ currentRound,getIndexContract,getUsdtContract,getProjectXContract,getProvider }}>
      {children}
    </WalletContext.Provider>
  );
};
export const WALLETCONTEXT = () => useContext(WalletContext);
